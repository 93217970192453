import React, { createContext, useState, useMemo, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getThemePreference, saveThemePreference } from "../graphql/queries";
import { useLazyQuery } from "@apollo/client";

export const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {
  const [mode, setMode] = useState("light");
  const [fetchThemePreference, { data: themeData}] = useLazyQuery(getThemePreference);
  const [saveThemePreferences] = useLazyQuery(saveThemePreference);
  const returnMode = (varMode) => {
    if (varMode === "L") {
      return "light";
    } else if (varMode === "D") {
      return "dark";
    } else if (varMode === "light") {
      return "L";
    } else if (varMode === "dark") {
      return "D";
    }
  };
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
        },
      }),
    [mode]
  );

  useEffect(() => {
    fetchThemePreference();
  }, [fetchThemePreference]);

  useEffect(() => {
    if (
      themeData &&
      themeData?.getDashboardMode &&
      themeData?.getDashboardMode?.data
    ) {
      setMode(returnMode(themeData.getDashboardMode?.data?.mode) || "light");
    }
  }, [themeData]);

  const toggleTheme = () => {
    const newMode = mode === "light" ? "dark" : "light";
    setMode(newMode);
    saveThemePreferences({ variables: { mode: returnMode(newMode) } });
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme, setMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
