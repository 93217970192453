import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  getAutomationFilters,
  getAutomationScoreCard,
  getAutomationValueMetrics,
  getAutomationAdoptionMetrics,
  getAutomationHealthMetrics,
} from "../graphql/automationQueries";
import { helperFunctions } from "../utils/helperFunctions";

const { getMonthShortFromDateString } = helperFunctions();

export const useAutomation = () => {
  const [automationFiltersData, setAutomationFiltersData] = useState([]);
  const [automationScorecardData, setAutomationScorecardData] = useState({});
  const [automationValueMetricsData, setAutomationValueMetricsData] = useState({});
  const [automationAdoptionMetricsData, setAutomationAdoptionMetricsData] =
    useState({});
  const [automationHealthMetricsData, setAutomationHealthMetricsData] =
    useState({});

  const [getAutomationFiltersData, { loading: automationFiltersLoading }] =
    useLazyQuery(getAutomationFilters, {
      onCompleted: (res) => {
        if (res) {
          const {
            getAutomationFilters: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setAutomationFiltersData(data);
          } else {
            setAutomationFiltersData([]);
          }
        }
      },
    });

  const [getAutomationScoreCardData, { loading: automationScorecardLoading }] =
    useLazyQuery(getAutomationScoreCard, {
      onCompleted: (res) => {
        if (res) {
          const {
            getAutomationScoreCard: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setAutomationScorecardData(data);
          } else {
            setAutomationScorecardData({});
          }
        }
      },
    });

  const [getAutomationValueMetricsData, { loading: automationValueMetricsLoading }] =
    useLazyQuery(getAutomationValueMetrics, {
      onCompleted: (res) => {
        if (res) {
          const {
            getAutomationValueMetrics: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setAutomationValueMetricsData(data);
          } else {
            setAutomationValueMetricsData({});
          }
        }
      },
    });

  const [
    getAutomationAdoptionMetricsData,
    { loading: automationAdoptionLoading },
  ] = useLazyQuery(getAutomationAdoptionMetrics, {
    onCompleted: (res) => {
      if (res && res.getAutomationAdoptionMetrics) {
        const {
          getAutomationAdoptionMetrics: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          const structureData = {
            metricsValue: "",
            month: [],
            value: [],
          };

          structureData.metricsValue = data?.totalAdoptionAchieved;

          data.adoptionTrend?.map((detail) => {
            const { dateTime, adoptionAchieved } = detail;
            structureData.month.push(getMonthShortFromDateString(dateTime));
            structureData.value.push(adoptionAchieved);
          });
          setAutomationAdoptionMetricsData(structureData);
        } else {
          setAutomationAdoptionMetricsData({});
        }
      } else {
        setAutomationAdoptionMetricsData({});
      }
    },
  });

  const [getAutomationHealthMetricsData, { loading: automationHealthLoading }] =
    useLazyQuery(getAutomationHealthMetrics, {
      onCompleted: (res) => {
        if (res && res.getAutomationHealthMetrics) {
          const {
            getAutomationHealthMetrics: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            const structureData = {
              metricsValue: "",
              month: [],
              value: [],
            };

            structureData.metricsValue = data?.totalHealthPercentage;

            data.healthTrend.map((detail) => {
              const { dateTime, healthPercentage } = detail;
              structureData.month.push(getMonthShortFromDateString(dateTime));
              structureData.value?.push(healthPercentage);
            });
            setAutomationHealthMetricsData(structureData);
          } else {
            setAutomationHealthMetricsData({});
          }
        } else {
          setAutomationHealthMetricsData({});
        }
      },
    });

  return {
    automationFiltersData,
    getAutomationFiltersData,
    automationFiltersLoading,
    automationScorecardData,
    getAutomationScoreCardData,
    automationScorecardLoading,
    automationValueMetricsData,
    getAutomationValueMetricsData,
    automationValueMetricsLoading,
    automationAdoptionMetricsData,
    getAutomationAdoptionMetricsData,
    automationAdoptionLoading,
    automationHealthMetricsData,
    getAutomationHealthMetricsData,
    automationHealthLoading,
  };
};
