import React, { useState, useEffect, useContext } from "react";
import * as Styled from "./style";
import { Grid, Button, useMediaQuery, useTheme } from "@mui/material";
import Select from "react-select";
import { constants } from "../../constants/Constants";
import { useAutomation } from "../../hooks/automation-hook";
import { helperFunctions } from "../../utils/helperFunctions";
import { FilterContext } from "../FilterBar/FilterProvider";
import { useLayoutNavigation } from "../../hooks/layout-navigation-hook";
import { ThemeContext } from "../../context/ThemeContext";

export default function AutomationFilters() {
  const { selectCustomStyles } = useLayoutNavigation();
  const theme = useTheme();
  const { mode } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const basicOption = { value: "All", label: "All" };

  const [toolOptions, setToolOptions] = useState([basicOption]);
  const [projectOptions, setProjectOptions] = useState([basicOption]);
  const [toolProjectMap, setToolProjectMap] = useState(new Map());

  const {
    selectedTool,
    setSelectedTool,
    selectedProject,
    setSelectedProject,
    toolAllOptions,
    setToolAllOptions,
    projectAllOptions,
    setProjectAllOptions,
  } = useContext(FilterContext);

  const {
    automationFiltersData,
    getAutomationFiltersData,
    automationFiltersLoading,
  } = useAutomation();

  const { getToolMapProject } = helperFunctions();

  function onToolFilterChange(selectedOption) {
    setSelectedTool(selectedOption);
    const availableProjectsOptions = toolProjectMap.get(selectedOption.value);
    setProjectOptions(availableProjectsOptions);
    setSelectedProject(availableProjectsOptions?.[0] ?? "");
  }

  function clearAllFilters() {
    setToolOptions(toolAllOptions);
    setProjectOptions(projectAllOptions);
    setSelectedTool(toolAllOptions?.[0] ?? "");
    setSelectedProject(projectAllOptions?.[0] ?? "");
  }

  useEffect(() => {
    getAutomationFiltersData({ variables: {} });
  }, [getAutomationFiltersData]);

  useEffect(() => {
    if (!automationFiltersLoading && automationFiltersData.length) {
      const { tools, projects, toolProjectMapping } = getToolMapProject(
        automationFiltersData
      );
      setToolAllOptions(tools);
      setProjectAllOptions(projects);
      setToolOptions(tools);
      setProjectOptions(projects);
      setToolProjectMap(toolProjectMapping);
      if (!selectedTool) {
        setSelectedTool(tools[0]);
      }
      if (!selectedProject) {
        setSelectedProject(projects[0]);
      }
    }
  }, [
    automationFiltersLoading,
    automationFiltersData,
    getToolMapProject,
    setToolAllOptions,
    setProjectAllOptions,
    selectedTool,
    selectedProject,
  ]);

  return (
    <Styled.AutomationFiltersContainer
      data-testid="automation-filters-container"
      mode={mode}
    >
      {isMobile ? (
        <Grid container xs={12} justifyContent="flex-start">
          <Grid item container xs={12}>
            <Grid item xs={12} className="automationFilterText">
              {constants?.automation?.tools}
            </Grid>
            <Grid item xs={4}>
              <Select
                options={toolOptions}
                isSearchable={false}
                placeholder={"All"}
                styles={selectCustomStyles}
                value={selectedTool}
                testId="automation-tools"
                onChange={onToolFilterChange}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={2.5}
            md={2}
            lg={1.5}
            xl={1}
            className="mobileProjectFilter"
          >
            <Grid item xs={12} className="automationFilterText">
              {constants?.automation?.projects}
            </Grid>
            <Grid item xs={4}>
              <Select
                options={projectOptions}
                isSearchable={false}
                placeholder={"All"}
                styles={selectCustomStyles}
                value={selectedProject}
                testId="automation-tools"
                onChange={setSelectedProject}
                menuPlacement="top"
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container xs={12} justifyContent="flex-end" alignItems="center">
          <Grid item container xs={12} sm={2.5} md={2} lg={1.5} xl={1}>
            <Grid item xs={12} className="automationFilterText">
              {constants?.automation?.tools}
            </Grid>
            <Grid item xs={12}>
              <Select
                options={toolOptions}
                isSearchable={false}
                placeholder={"All"}
                styles={selectCustomStyles}
                value={selectedTool}
                testId="automation-tools"
                onChange={onToolFilterChange}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={2.5}
            md={2}
            lg={1.5}
            xl={1}
            className="projectFilter"
          >
            <Grid item xs={12} className="automationFilterText">
              {constants?.automation?.projects}
            </Grid>
            <Grid item xs={12}>
              <Select
                options={projectOptions}
                isSearchable={false}
                placeholder={"All"}
                styles={selectCustomStyles}
                value={selectedProject}
                testId="automation-tools"
                onChange={setSelectedProject}
                maxMenuHeight={150}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={1.7} md={1.2} lg={1} xl={0.7}>
            <Grid item xs={0} ms={12}>
              &nbsp;
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button className="clearAllButton" onClick={clearAllFilters}>
                {constants?.automation?.clearAll}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Styled.AutomationFiltersContainer>
  );
}
