import React from "react";
import Chip from "@mui/material/Chip";

const CustomChip = ({ label, positionTop = "8px", positionRight = "10px" }) => {
  const customStyles = {
    backgroundColor: "#FFE5B3",
    color: "#000",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    display: "flex",
    height: "24px",
    padding: "2px 4px",
    alignItems: "center",
    gap: "2px",
    position: "relative",
    right: positionRight || "10px",
    top: positionTop || "8px",
    marginLeft:"12px"
  };

  return <Chip label={label} sx={customStyles} />;
};

export default CustomChip;
