export const oktaLogin = (oktaAuth) => {
  oktaAuth.signInWithRedirect({ originalUri: "/" });
};

export const oktaLogout = async (oktaAuth) => {
  localStorage.clear();
  await oktaAuth.revokeAccessToken(); // strongly recommended
  await oktaAuth.signOut({
    clearTokensBeforeRedirect: true,
  });
};

export const getAccessToken = (authState) => {
  return authState.accessToken;
};

export const getIdToken = (authState) => {
  return authState.idToken;
};

export const getRefreshToken = (authState) => {
  return authState.refreshToken;
};

export const getRenewRefreshToken = (authState, oktaAuth) => {
  //Renew either idtoken or accesstoken
  const tokenToRenew = {
    //idToken: authState.idToken,
    //accessToken:authState.accessToken,
    claims: {
      /* token claims */
    },
    expiresAt: authState.expiresAt,
    scopes: [
      "openid",
      "profile",
      "email",
      "offline_access",
      "pepapppsdashboard",
    ],
    authorizeUrl: window.REACT_APP_AuthorizeUrl,
    issuer: window.REACT_APP_ISSUER,
    clientId: window.REACT_APP_CLIENT_ID,
  };

  return oktaAuth.token
    .renew(tokenToRenew)
    .then(function (freshToken) {
      return freshToken;
    })
    .catch(function (err) {
      return err;
    });
};

export const isOktaAuthenticated = (authState) => {
  return authState ? authState?.isAuthenticated : false;
};

export const getOktaUserClaims = (oktaAuth) => {
  return oktaAuth
    .getUser()
    .then((info) => {
      return info;
    })
    .catch((err) => {
      return err;
    });
};

/*This method will take care of disabling okta
   Will remove this method once OKTA details provided
 */
export const CheckLocalEnv = () => {
  return window.REACT_APP_LocalEnv !== "false";
};
