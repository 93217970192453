import React, { useContext } from "react";
import { Checkbox } from "@mui/material";
import * as Styled from "./style";
import DatePicker from "react-datepicker";
import { color } from "../../theme/Color";
import { MdOutlineCalendarToday } from "react-icons/md";
import { constants } from "../../constants/Constants";
import { ThemeContext } from "../../context/ThemeContext";

export function CustomCheckbox({ isChecked, handleCheckboxChange }) {
  return (
    <label>
      <Checkbox
        checked={isChecked}
        data-testid="ytd-checkbox"
        onChange={handleCheckboxChange}
        size="small"
        color="primary"
        sx={{
          color: "#fff",
          padding: "0px",
          "&.Mui-checked": {
            // color: "#fff",
          },
        }}
        inputProps={{ "aria-label": "controlled" }}
      />
      <span className="checkbox-label">YTD</span>
    </label>
  );
}

export function CustomDatePicker({
  setSelectedStartDate,
  setStartUTCDate,
  setSelectedEndDate,
  setEndUTCDate,
  getStartOfMonth,
  getEndOfMonth,
  getCurrentDayOfMonth,
  portalId,
  product,
  pickerType,
  minSelectableYear,
  maxSelectableYear,
  setSelectedYear,
  selectedYear,
  selectedDate,
  setSelectedDate,
  checked,
}) {
  const isCurrentDate = (date) => {
    const today = new Date();
    return (
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };
  const { mode } = useContext(ThemeContext);
  const handleDateChangeLogic = (date) => {
    const year = date.getFullYear();
    const currentYear = new Date().getFullYear();
    if (pickerType === "year") {
      setSelectedYear(year);
      const dateValue = currentYear === year ? new Date() : new Date(year, 11, 1);
      setSelectedDate(dateValue);
      setSelectedStartDate(dateValue);
      const startUTCDate = checked
        ? getStartOfMonth(date)?.toISOString()
        : getStartOfMonth(dateValue)?.toISOString();
      setStartUTCDate(startUTCDate); // Assigning the date value selected from year picker
      if (
        isCurrentDate(date) &&
        product?.productId === constants?.products?.ShipmentVisibilty
      ) {
        const currentDate = getCurrentDayOfMonth();
        setSelectedEndDate(currentDate);
        setEndUTCDate(currentDate?.toISOString());
      } else {
        const endOfMonth = getEndOfMonth(date);
        setSelectedEndDate(endOfMonth);
        setEndUTCDate(endOfMonth?.toISOString());
      }
      const endOfMonth = getEndOfMonth(dateValue);
      setSelectedEndDate(endOfMonth);
      setEndUTCDate(endOfMonth?.toISOString());
    } else if (pickerType === "month") {
      setSelectedDate(date);
      setSelectedStartDate(date);
      setStartUTCDate(getStartOfMonth(date)?.toISOString());
      if (
        isCurrentDate(date) &&
        product?.productId === constants?.products?.ShipmentVisibilty
      ) {
        const currentDate = getCurrentDayOfMonth();
        setSelectedEndDate(currentDate);
        setEndUTCDate(currentDate?.toISOString());
      } else {
        const endOfMonth = getEndOfMonth(date);
        setSelectedEndDate(endOfMonth);
        setEndUTCDate(endOfMonth?.toISOString());
      }
    }
  };

  const handleSelectedDate = () => {
    return pickerType === "year" ? new Date(selectedYear, 0, 1) : selectedDate;
  };

  return (
    <Styled.DatePickerContainer mode={mode} style={{ position: "relative" }} >
      <Styled.GlobalStyle mode={mode} />
      <label>
        <DatePicker
          id={"date-filter"}
          data-testid="date-filter"
          portalId={portalId}
          className={
            pickerType === "month" && checked
              ? "disabledFilter filter"
              : "filter"
          }
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onChange={handleDateChangeLogic}
          disabledKeyboardNavigation
          placeholderText={pickerType === "year" ? "Year" : "Month"}
          enableTabLoop={false}
          showMonthYearPicker={pickerType === "month"}
          showYearPicker={pickerType === "year"}
          selected={handleSelectedDate()}
          dateFormat={pickerType === "year" ? "yyyy" : "MMMM"}
          minDate={
            pickerType === "year"
              ? new Date(minSelectableYear, 0, 1)
              : new Date(selectedYear, 0, 1)
          }
          maxDate={
            pickerType === "year"
              ? new Date(maxSelectableYear, 11, 31)
              : new Date().getFullYear() === selectedYear
              ? new Date()
              : new Date(selectedYear, 11, 31)
          }
          showDisabledMonthNavigation={false}
          readOnly={pickerType === "month" && checked}
          renderCustomHeader={
            pickerType === "year"
              ? () => {
                  return <div>2023-2028</div>;
                }
              : () => {
                  return <div>{selectedYear}</div>;
                }
          }
          // startDate={pickerType === "year" ? new Date(minSelectableYear, 0, 1) : new Date(selectedYear,0,1)}
        />
        <Styled.IconContainer>
          <MdOutlineCalendarToday
            data-accessible={true}
            style={{
              color: color._616161,
              cursor: "pointer",
            }}
            data-testid="datepicker_calendar"
          />
        </Styled.IconContainer>
      </label>
    </Styled.DatePickerContainer>
  );
}
