import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { getDSDFilters } from "../graphql/walmartQueries";

export const useDSD = () => {
  const [dsdFiltersData, setDsdFiltersData] = useState({});

  const [getDsdFiltersData, { loading: dsdFiltersLoading }] =
    useLazyQuery(getDSDFilters, {
      fetchPolicy: "cache-first",
      onCompleted: (res) => {
        if (res) {
          const {
            getDSDFilters: { data, responseCode },
          } = res;
          let structuredData = {};
          if (data && responseCode === 200) {
            structuredData = data;
          }
          setDsdFiltersData(structuredData);
        }
      },
    });

  return {
    dsdFiltersData,
    getDsdFiltersData,
    dsdFiltersLoading,
  };
};