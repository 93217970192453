/**
 API Mocking process
 */
import _primaryNavigation from "./PrimaryNavigationData.json";

const TIMEOUT = 100;

export default {
  getPrimaryNavigation: (getFrom, timeout) =>
    setTimeout(() => getFrom(_primaryNavigation), timeout || TIMEOUT),
};
