import React, {useContext} from "react";
import * as Styled from "./style";
import { Grid, useMediaQuery } from "@mui/material";
import Select from "react-select";
import { constants, screenSizes } from "../../constants/Constants";
import { useLayoutNavigation } from "../../hooks/layout-navigation-hook";
import { ThemeContext } from "../../context/ThemeContext";

export default function ShipmentVisibilityFilter({
  selectedStore,
  setSelectedStore,
  storeOptions,
}) {
  const { mode } = useContext(ThemeContext);
  const isDesktop = useMediaQuery(screenSizes.desktop);
  const { selectCustomStyles } = useLayoutNavigation();
  return (
    <Styled.ShipmentVisibilityFilterContainer data-testid="shipment-visibility-filter-container"
    mode={mode}>
      <Grid
        item
        container
        xs={12}
        justifyContent={isDesktop ? "flex-end" : "flex-start"}
        alignContent="center"
      >
        <Grid item container xs={4} sm={3} md={2} lg={1.5} xl={1}>
          <Grid item xs={12} className="shipmentVisibilityFilterText">
            {constants?.operationalMetrics?.shipmentVisibilty?.stores}
          </Grid>
          <Grid item xs={12}>
            <Select
              options={storeOptions}
              isSearchable={false}
              placeholder={"All"}
              styles={selectCustomStyles}
              value={selectedStore}
              testId="automation-tools"
              onChange={setSelectedStore}
            />
          </Grid>
        </Grid>
      </Grid>
    </Styled.ShipmentVisibilityFilterContainer>
  );
}
