import { gql } from "@apollo/client";

const getAutomationFilters = gql`
  query {
    getAutomationFilters {
      data {
        toolName
        projects
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAutomationScoreCard = gql`
  query($automationCriteria: AutomationMetricsRequestDTOInput!) {
    getAutomationScoreCard(automationCriteria: $automationCriteria) {
      data {
        totalValueGenerated
        totalValueUpliftPecentage
        totalAdoptionAchieved
        totalAdoptionUpliftPecentage
        totalHealthPercentage
        totalHealthUpliftPecentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAutomationValueMetrics = gql`
  query($automationCriteria: AutomationMetricsRequestDTOInput!) {
    getAutomationValueMetrics(automationCriteria: $automationCriteria) {
      data {
        totalValueGenerated
        valueTrend {
            dateTime
            valueGenerated
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAutomationAdoptionMetrics = gql`
  query ($automationCriteria: AutomationMetricsRequestDTOInput!) {
    getAutomationAdoptionMetrics(automationCriteria: $automationCriteria) {
      data {
        totalAdoptionAchieved
        adoptionTrend {
          dateTime
          adoptionAchieved
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAutomationHealthMetrics = gql`
  query ($automationCriteria: AutomationMetricsRequestDTOInput!) {
    getAutomationHealthMetrics(automationCriteria: $automationCriteria) {
      data {
        totalHealthPercentage
        healthTrend {
          dateTime
          healthPercentage
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

export {
  getAutomationFilters,
  getAutomationScoreCard,
  getAutomationValueMetrics,
  getAutomationAdoptionMetrics,
  getAutomationHealthMetrics,
};