import styled from "styled-components";
import { color } from "../../theme/Color";

export const Header = styled.div`
  background: ${color._131313};
  position: fixed;
  width: 100%;
  z-index: 2;
`;

export const Container = styled.div`
  & .arrowDropDownIconIcon {
    height: 24px;
    width: 24px;
    margin-left: 2px;
    margin-top: 4px;
    border-radius: 0px;
  }

  & .avatarIcon {
    height: 32px;
    width: 32px;
    margin-left: 25px;
    margin-top: 0px;
    border-radius: 0px;
  }

  & .calendarIcon {
    height: 24px;
    width: 24px;
    margin-left: 25px;
    margin-top: 4px;
    border-radius: 0px;
  }

  & .notificationIcon {
    height: 24px;
    width: 24px;
    margin-top: 4px;
    border-radius: 0px;
  }

  & .pepsicoIcon {
    margin-left: 24px;
    height: 22px;
  }
  & .hamburgerIcon {
    display: none;
  }

  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  position: relative;

  @media (max-width: 600px) {
    .pepsicoIcon {
      display: none;
    }
    .hamburgerIcon {
      font-size: 24px;
      color: #c0c0c0;
      display: block;
      padding: 30px;
    }
  }

  @media (min-width: 769px) {
    .pepsicoIcon {
      display: block;
    }
    .hamburgerIcon {
      display: none;
    }
  }
`;

export const LeftContainer = styled.div`
  width: 100px;
  border-radius: 10px;
`;

export const MiddleContainer = styled.div`
  height: 22px;
  width: 150px;
  margin-left: 0px;
  margin-top: 0px;
  border-radius: 0px;
  & .psdTitle {
    color: ${color._efefef};
    font-size: 16px;
    font-weight: 700;
  }
`;

export const RightContainer = styled.div`
  font-size: 2.4em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  & .OtherIcon {
    display: flex;
    margin: 0 10px;
    cursor: pointer;
  }
`;

export const ProfileDropdown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 64px;
  right: 0px;
  background-color: ${color._272727};
  width: 200px;
  color: ${color._cdcdcdcf};
  border-radius: 0 0 4px 4px;
`;

export const DropdownRow = styled.div`
  display: flex;
  font-size: 18px;
  // padding: 8px 12px;
  align-items: center;
  border-bottom: ${({ index }) => (index === 2 ? `2px solid` : "none")}
    ${color._313131};
  cursor: pointer;

  &:hover {
    background-color: ${color._3a3a3a};
  }
  & .title {
    font-size: 15px;
  }
`;
