import styled from "styled-components";
import { color } from "../../theme/Color";

export const ShipmentVisibilityFilterContainer = styled.div`
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  color: ${color._efefef};

  & .shipmentVisibilityFilterText {
    color: ${({ mode }) => (mode === "light" ? color._616161 : color._d0d0d0)};
    font-size: 12px;
    margin-bottom: 8px;
  }
`;