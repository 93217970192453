import React from "react";
import { ApolloProvider } from "@apollo/client";
import "./App.css";
import AppOktaRouter from "./routes/AppWithRouter";
import { FullstoryWrapper } from "./components/FullStory/Fullstory";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { oktaLogin } from "./utils/okta_auth";
import { createApolloClient } from "./utils/apolloUtils";
import ThemeContextProvider from "context/ThemeContext";

const oktaAuth = new OktaAuth({
  issuer: window.REACT_APP_ISSUER,
  clientId: window.REACT_APP_CLIENT_ID,
  redirectUri: window.REACT_APP_REDIRECT_URI,
  postLogoutRedirectUri: window.REACT_APP_LOGOUT_URI,
  scopes: ["openid", "profile", "email", "offline_access", "pepapppsdashboard"],
  pkce: window.REACT_APP_PKCE,
});

function App() {
  // const { oktaAuth } = useOktaAuth();
  const triggerLogin = async () => {
    oktaLogin(oktaAuth);
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      await triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
    }
  };

  return (
    <>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
        <ApolloProvider client={createApolloClient(oktaAuth)}>
          <ThemeContextProvider>
            <FullstoryWrapper organization={window.REACT_APP_FULLSTORY_ORG}>
              <AppOktaRouter />
            </FullstoryWrapper>
          </ThemeContextProvider>
        </ApolloProvider>
      </Security>
    </>
  );
}

export default App;
