import { gql } from "@apollo/client";

const getDSDFilters = gql`
  query {
    getDSDFilters {
      data {
        storeId
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getShipmentVisibilityScoreCard = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getShiVsScoreCard(psdCriteria: $psdCriteria) {
      data {
        avgDeliveriesPerWeek
        plannedETAWindowAccuracy
        startedETAWindowAccuracy
        enrouteETAWindowAccuracy
        totalStores
        totalShipments
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getDwellTimeVolumeDetInMinProd = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getDwellTimeVolumeDetInMin(psdCriteria: $psdCriteria) {
      data {
        overallstDeviation
        range {
          rangeLowerLimit
          rangeUpperLimit
          avgDwellTime
          countOfShipments
        }
        averageShipment
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getSolutionMonitoringView = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getShipmentMissingEvent(psdCriteria: $psdCriteria) {
      data {
        sequencingInPercentage
      }
      errors
      responseCode
      responseMessage
    }
    getAdapterService(psdCriteria: $psdCriteria) {
      data {
        upTime
        messagesReceived
        messagesProcessed
        messagesSuccessRate
      }
      errors
      responseCode
      responseMessage
    }
    getVisibilityService(psdCriteria: $psdCriteria) {
      data {
        upTime
        messagesReceived
        messagesProcessed
        messagesSuccessRate
      }
      errors
      responseCode
      responseMessage
    }
    getSubscriptionService(psdCriteria: $psdCriteria) {
      data {
        upTime
        messagesReceived
        messagesProcessed
        messagesSuccessRate
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getIncidentMonitoringView = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getIncidentTrend(applicationMetricCriteria: $applicationMetricCriteria) {
      data {
        dateTime
        incidentCount
        resolvedWithInSla
        responseWithInSla
      }
      errors
      responseCode
      responseMessage
    }
    getIncidentMonitoringTableView(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        incidentNumber
        category
        priority
        openDateTime
        assigned
        status
      }
      errors
      responseCode
      responseMessage
    }
    getIncidentMonitoringSlaAnalysis(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        slaType
        incidentCount
        incidentPercentage
        incidentOverallAvgPercentage
      }
      errors
      responseCode
      responseMessage
    }
    getIncidentMonitoringPriorityLevelGraph(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        dateTime
        sumOfIncidentCount
        priorityDetail {
          priority
          incidentCount
        }
      }
      errors
      responseCode
      responseMessage
    }
    getIncidentMonitoringOpenAndClose(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        openIncident
        closedIncident
      }
      errors
      responseCode
      responseMessage
    }
    getIncidentMonitoringOverview(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        incidentCount
        responseTime
        vsPlanResponseTime
        resolutionTime
        vsPlanResolutionTime
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

export {
  getDSDFilters,
  getShipmentVisibilityScoreCard,
  getDwellTimeVolumeDetInMinProd,
  getSolutionMonitoringView,
  getIncidentMonitoringView,
};
