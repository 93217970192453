import React, { useState, useContext } from "react";
import { Typography, Avatar, Grid } from "@mui/material";
import * as Styled from "./style";
import { FilterContext } from "../../components/FilterBar/FilterProvider";
import { color } from "../../theme/Color";
import { ReactComponent as PepsicoIcon } from "../../assets/images/Pepsico.svg";
import { ReactComponent as ArrowDropDownIcon } from "../../assets/images/ArrowDropDown.svg";
import { MdLogout, MdMenu } from "react-icons/md";
import CustomChip from "../CustomChip/CustomChip";
import CustomSwitch from "../CustomSwitch/CustomSwitch";
import { useNavigate } from "react-router-dom";
import ThemeToggle from "../../components/ThemeToggle/ThemeToggle";

const UserDropdown = ({ toggleValue, handleLogout, handleToggleBeta }) => {
  return (
    <Styled.ProfileDropdown
      data-accessible={true}
      data-testid="header_profiledropdown"
    >
      <Styled.DropdownRow
        onClick={handleToggleBeta}
        data-accessible={true}
        data-testid="header_togglebeta"
      >
        <Grid container direction={"row"} alignItems={"center"}>
          <Grid item sx={{ padding: "8px" }}>
            <CustomSwitch
              checked={toggleValue}
              inputProps={{ "aria-label": "ant design" }}
            />
          </Grid>
          <Grid item sx={{ padding: "8px" }}>
            <div className="title" data-accessible={true}>
              Beta Features
            </div>
          </Grid>
        </Grid>
      </Styled.DropdownRow>
      <Styled.DropdownRow
        onClick={handleLogout}
        data-accessible={true}
        data-testid="header_logout"
      >
        <Grid container direction={"row"} alignItems={"center"}>
          <Grid item sx={{ padding: "8px" }}>
            <MdLogout />
          </Grid>
          <Grid item sx={{ padding: "8px" }}>
            <div
              className="title"
              data-accessible={true}
              style={{ paddingLeft: "10px" }}
            >
              Logout
            </div>
          </Grid>
        </Grid>
      </Styled.DropdownRow>
    </Styled.ProfileDropdown>
  );
};

const Header = ({ setSidebar }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const navigate = useNavigate();
  const handleProfileClick = () => setShowDropDown(!showDropDown);
  const handleMenuClick = () => {
    setSidebar((prevState) => !prevState);
  };
  /**
   * TO DO: Un comment snippet once OKTA gets configured;
   */

  const { userInitial, betaFeatureToggle, setBetaFeatureToggle } =
    useContext(FilterContext) ?? {};
  return (
    <Styled.Header data-testid={"header"}>
      <Styled.Container data-testid="header_container">
        <Styled.LeftContainer data-testid="header_leftcontainer">
          <PepsicoIcon
            data-testid="header_pepsicoIcon"
            className="pepsicoIcon"
          ></PepsicoIcon>
          <MdMenu
            onClick={handleMenuClick}
            className="hamburgerIcon"
            data-testid="header_side_menu_icon"
          />
        </Styled.LeftContainer>
        <Styled.MiddleContainer data-testid="header_middlecontainer">
          <Typography className="psdTitle">Product Success</Typography>
        </Styled.MiddleContainer>
        <Styled.RightContainer>
          <div
            className="OtherIcon"
            data-testid="header_rightcontainer_OtherIcon"
          >
            <ThemeToggle/>
            {betaFeatureToggle && <CustomChip label={"Beta"} />}
            <Avatar
              sx={{
                width: 36,
                height: 36,
                color: color?._005CBC ?? "white",
                backgroundColor: color?._DFEAF9 ?? "black",
                fontSize: "18px",
                fontWeight: "700",
                marginLeft:"10px"
              }}
              onClick={handleProfileClick}
              data-testid="header_avatarIcon"
            >
              {userInitial}
            </Avatar>
            <ArrowDropDownIcon
              data-testid="header_arrowDropDownIconIcon"
              className="arrowDropDownIconIcon"
              onClick={handleProfileClick}
            ></ArrowDropDownIcon>
          </div>
        </Styled.RightContainer>
        {showDropDown && (
          <UserDropdown
            toggleValue={betaFeatureToggle}
            handleLogout={() => {
                navigate("/logout");
            }}
            handleToggleBeta={() => {
              setBetaFeatureToggle(!betaFeatureToggle);
              setShowDropDown(!showDropDown);
            }}
          />
        )}
      </Styled.Container>
    </Styled.Header>
  );
};

export default Header;
