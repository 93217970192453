import React, { useContext, lazy, useEffect } from "react";
import { FilterContext } from "../../components/FilterBar/FilterProvider";
import BasicTabs from "../../components/Tab/BasicTabs";
import { useLocation } from "react-router-dom";
import { constants } from "../../constants/Constants";
import { helperFunctions } from "../../utils/helperFunctions";

const LazyOverView = lazy(() => import("../../containers/OverView"));
const LazyOrderStatus = lazy(() => import("../../containers/OrderStatus/"));
const LazyOperationalMetrics = lazy(
  () => import("../../containers/OperationalMetrics")
);
const LazyBeesVsPepsiConnectMetrics = lazy(
  () => import("../../containers/BeesVsPepsiConnectMetricsContainer")
);
const LazyApplicationPerformance = lazy(
  () => import("../../containers/ApplicationPerformance")
);
const LazyUserBehavior = lazy(
  () => import("../../containers/UserBehaviorEngagement/")
);
const LazyApplicationPerformanceRedesigned = lazy(
  () => import("../../containers/ApplicationPerformanceRedesigned")
);
const LazyValueRealization = lazy(
  () => import("../../containers/ValueRealization")
);
const Dashboard = React.memo(() => {
  const {
    selectedProduct,
    selectedSector,
    productValues,
    setSelectedProduct,
    selectedModel,
    betaFeatureToggle,
  } = useContext(FilterContext);

  const location = useLocation();
  const { state } = location;
  const { selectedProductId } = state || {};
  let defaultValue = 1;
  if (productValues && productValues.length > 0) {
    defaultValue = productValues[0].productId;
  }
  const productId = selectedProductId || defaultValue;
  const { isDevOrQaEnv } = helperFunctions();

  useEffect(() => {
    if (Array.isArray(productValues) && productValues.length > 0) {
      const filteredProduct = productValues.find(
        (prod) => prod.productId === productId
      );
      setSelectedProduct(filteredProduct || null);
    }
  }, [productValues, setSelectedProduct, productId]);

  const tabArray = [
    { id: 1, label: "Overview", component: LazyOverView },
    { id: 2, label: "Order Board", component: LazyOrderStatus },
    { id: 3, label: "Value Creation", component: LazyOperationalMetrics },
    {
      id: 4,
      label: "Bees Vs PepsiConnect Metrics",
      component: LazyBeesVsPepsiConnectMetrics,
    },
    {
      id: 5,
      label: "App Performance",
      component: LazyApplicationPerformanceRedesigned,
    },
    {
      id: 6,
      label: "User Adoption",
      component: LazyUserBehavior,
    },
  ];
  const applicationPerformTab = [
    {
      id: 7,
      label: "App Performance",
      component: LazyApplicationPerformance,
    },
  ];
  const valueRealizationTab = [
    {
      id: 9,
      label: "Value Realization",
      component: LazyValueRealization,
    },
  ];
  function getTabsForProduct(selectedProduct) {
    const productId = selectedProduct?.productId;
    if (productId === constants.products.PepsiConnect) {
      const filteredTabs = tabArray.filter(
        (item) => item.id !== 4 && item.id !== 2
      ); // Remove BEES and Order Board Tab
      {
        return [...filteredTabs];
      }
    } else if (productId === constants.products.R360) {
      return [tabArray[0], tabArray[2], tabArray[5]]; //applicationPerformTab[0]
    } else if (
      productId === constants.products.GuidedSelling &&
      selectedModel?.id === constants?.modals?.suggestedOrder
    ) {
      return [
        tabArray[0],
        tabArray[2],
        // valueRealizationTab[0], // Hide Value Realization Tab for GS-SO
        applicationPerformTab[0],
      ];
    } else if (
      productId === constants.products.GuidedSelling &&
      selectedModel?.id === constants?.modals?.productPairing
    ) {
      return [tabArray[2]];
    } else if (
      productId === constants.products.GuidedSelling &&
      selectedModel?.id === constants?.modals?.nbA
    ) {
      return [tabArray[0]];
    } else if (
      productId === constants.products.GuidedSelling &&
      selectedModel?.id === constants?.modals?.shareWise
    ) {
      return [tabArray[2]];
    } else if (
      productId === constants.products.E2EWaste ||
      productId == constants.products.MLT
    ) {
      if (betaFeatureToggle) {
        return [tabArray[0], applicationPerformTab[0], tabArray[5]];
      } else {
        return [tabArray[0], applicationPerformTab[0]];
      }
    } else if (productId === constants.products.ShipmentVisibilty) {
      return [tabArray[0], tabArray[2], applicationPerformTab[0]];
    } else if (productId === constants.products.Automation) {
      return [tabArray[0], tabArray[2]];
    } else if (productId === constants.products.Canvas) {
      return [tabArray[0], tabArray[2], tabArray[4], tabArray[5]];
    } else if (
      productId === constants.products.PromoAI ||
      productId === constants.products.PricingAI
    ) {
      return [tabArray[0], tabArray[4], tabArray[5]];
    } else if (productId === constants.products.DTC) {
      return [tabArray[0], tabArray[2], tabArray[4]];
    } 
    else if (productId === constants.products.ManufacturingControlTower) {
      return [tabArray[0], tabArray[2], tabArray[4], tabArray[5]];
    }
    else if (productId === constants.products.ShouldCostModelling) {
      return [tabArray[4]];
    } 
    else if (productId === constants.products.MenuPro) {
      return [tabArray[5]];
    } 
    else {
      return tabArray;
    }
  }

  const selectedTabs =
    selectedProduct &&
    selectedProduct.productId !== null &&
    selectedProduct.productId !== undefined
      ? getTabsForProduct(selectedProduct)
      : tabArray.filter((item) => item.id !== 4);

  return (
    <>
      {selectedProduct && selectedTabs.length > 0 && (
        <BasicTabs
          tabArray={selectedTabs}
          product={selectedProduct}
          model={selectedModel}
          sector={selectedSector}
        />
      )}
    </>
  );
});

export default Dashboard;
