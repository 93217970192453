import styled, { createGlobalStyle } from "styled-components";
import { color } from "../../theme/Color";

export const FilterBarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & .filter-button {
    color: #fff;
    border-radius: 24px;
    background: #0054ac;
    text-transform: none;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    padding-left: 16px;
    padding-right: 16px;
    height: 32px;
  }
`;

export const GlobalStyle = createGlobalStyle`
  .react-datepicker__input-container {
    input {
      background-color: ${({ mode }) =>
        mode === "light"
          ? `${color._FBFCFE} !important`
          : `${color._272727} !important`};
      color: ${({ mode }) =>
        mode === "light" ? "#000 !important" : "#fff !important"};
    }
  }
  .react-datepicker {
    background-color: ${({ mode }) =>
      mode === "light"
        ? `${color._FBFCFE} !important`
        : `${color._272727} !important`};
    color: ${({ mode }) => (mode === "light" ? "#000" : "#fff")};
    border: ${({ mode }) =>
      mode === "light"
        ? "1px solid #ccc !important"
        : "1px solid #3a3a3a !important"};
  }
  .react-datepicker__header--custom {
    background-color: ${({ mode }) =>
      mode === "light" ? `${color._FBFCFE} !important` : "transparent !important"};
    border-bottom: ${({ mode }) =>
      mode === "light" ? "1px solid #ccc !important" : "none !important"};
  }
  .react-datepicker__year--container {
    background-color: ${({ mode }) =>
      mode === "light" ? `${color._FBFCFE} !important` : `${color._131216} !important`};
    border: ${({ mode }) =>
      mode === "light" ? "1px solid #ccc !important" : "none !important"};
    color: ${({ mode }) => (mode === "light" ? "#000" : "#fff")};
  }
  .react-datepicker__month-container {
    background-color: ${({ mode }) =>
      mode === "light" ? `${color._FBFCFE} !important` : `${color._131216} !important`};
    border: ${({ mode }) =>
      mode === "light" ? "1px solid #ccc !important" : "none !important"};
  }
  .react-datepicker__month-text {
    color: ${({ mode }) => (mode === "light" ? "#000 !important" : "#fff !important")};
  }
  .react-datepicker__year-text {
    color: ${({ mode }) => (mode === "light" ? "#000 !important" : "#fff !important")};
  }
  .react-datepicker__year-text--disabled {
    color: ${({ mode }) =>
      mode === "light" ? `${color._616161} !important` : "darkgray !important"};
  }
  .react-datepicker__month-text--disabled {
    color: ${({ mode }) =>
      mode === "light" ? `${color._616161} !important` : "darkgray !important"};
  }
`;

export const DatePickerContainer = styled.div`
  position: relative;

  & .CalenderPlacement {
    float: right;
    position: relative;
    margin-top: -23px;
    font-size: 15px;
    background: ${color._FBFCFE};
    margin-right: 5px;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 55%;
  right: 8px;
  transform: translateY(-50%);
  @media (min-width: 300px) and (max-width: 600px) {
    right: 230px;
  }
`;
