import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { IconButton } from '@mui/material';
import { ReactComponent as MoonIcon } from '../../assets/icons/moon.svg'; 
import { ReactComponent as SunIcon } from '../../assets/icons/sun.svg';  

const ThemeToggle = () => {
  const { mode, toggleTheme } = useContext(ThemeContext);

  return (
    <IconButton onClick={toggleTheme}  style={{ color: "white" }}>
      {mode === 'light' ? <SunIcon /> : <MoonIcon />}
    </IconButton>
  );
};

export default ThemeToggle;

