import React, { useEffect } from "react";
import * as FullStory from "@fullstory/browser";
import { useLayoutNavigation } from "../../hooks/layout-navigation-hook";

export const FullstoryWrapper = (props) => {
  return props.organization ? <Fullstory {...props} /> : props.children;
};

const Fullstory = ({ children, organization }) => {
  const { getUserRoles, userProfile } = useLayoutNavigation();
  useEffect(() => {
    if (localStorage.getItem("okta-token-storage")) {
      try {
        getUserRoles();
      } catch (error) {
        console.error("Error fetching user roles:", error);
      }
    }
  }, []);
  useEffect(() => {
    FullStory.init({ orgId: organization }, ({ sessionUrl }) =>
      console.log(`Started session: ${sessionUrl}`)
    );
  }, [organization]);
  useEffect(() => {
    FullStory.identify(userProfile?.gpId, {
      name: userProfile?.name,
      email: userProfile?.email,
    });
  }, [userProfile]);

  return children;
};
