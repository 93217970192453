import styled from "styled-components";
import { color } from "../../theme/Color";

export const AutomationFiltersContainer = styled.div`
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  color: ${color._efefef};

  & .automationFilterText {
    color: ${({ mode }) => (mode === "light" ? color._616161 : color._d0d0d0)};
    font-size: 12px;
    margin-bottom: 8px;
  }

  & .projectFilter {
    margin-left: 16px;
    margin-right: 16px;
  }

  & .clearAllButton {
    color: #3387df;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    text-transform: none;
    margin-bottom: 5px;
  }

  & .mobileProjectFilter {
    margin-top: 16px;
    margin-bottom: 12px;
  }
`;
