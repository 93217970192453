import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  from,
  Observable,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const createApolloClient = (oktaAuth) => {
  const token = oktaAuth.getAccessToken();

  const primaryHttpLink = new HttpLink({
    uri: process.env.REACT_APP_CAF_URI || window.REACT_APP_CAF_URI,
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const opNameLink = new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
      oktaAuth.tokenManager
        .get("accessToken")
        .then((accessToken) => {
          const token = accessToken?.accessToken;
          operation.setContext(({ headers }) => ({
            headers: {
              ...headers,
              authorization: token ? `Bearer ${token}` : "",
            },
          }));

          const subscription = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });

          return () => {
            if (subscription) {
              subscription.unsubscribe();
            }
          };
        })
        .catch((err) => {
          console.error("Error getting access token:", err);
          observer.error(err);
        });
    });
  });

  return new ApolloClient({
    link: from([errorLink, opNameLink, primaryHttpLink]),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
      },
    },
  });
};

export { createApolloClient };
