const titlesMap = {};
export const isCafURI = (contextURI) => {
  const cafURI = process.env.REACT_APP_CAF_URI || window.REACT_APP_CAF_URI;
  return contextURI === cafURI;
};
window.isCafURI = isCafURI;

const getPageTitle = (pathname) => {
  return titlesMap[pathname] || pathname;
};

/**
 * Function to set custom user data obj on window for using it in Appd
 */
export const setCustomUserData = (userObj = {}) => {
  const { customUserData = {} } = window;
  console.log("AppD: set custom user data:", customUserData);
  window.customUserData = {
    ...customUserData,
    ...userObj,
  };
};

/**
 * Function to frame custom page title on window for using it in Appd
 */
export const setCustomPageName = (pathname) => {
  const pageTitle = getPageTitle(pathname);
  console.log("AppD: set current page title:", pageTitle);
  window.customPageTitle = pageTitle;
};

/**
 * Function to get customers screen resolution
 */
export const getScreenResolution = () => {
  const realWidth = window.screen.width * window.devicePixelRatio;
  const realHeight = window.screen.height * window.devicePixelRatio;
  console.log(`AppD: screen resolution: ${realWidth} x ${realHeight}`);
  return `${realWidth}x${realHeight}`;
};