import { useOktaAuth } from "@okta/okta-react";
import { oktaLogin, isOktaAuthenticated } from "../utils/okta_auth";


export const useOkta = () => {
  const { authState, oktaAuth } = useOktaAuth();
  async function authenticate() {
    if (!authState) return;

    if (!isOktaAuthenticated(authState)) {
      oktaLogin(oktaAuth);
    }
  }

  return {    
    authState,
    authenticate
  };
};