import React, { createContext, useState, useEffect } from "react";
import { useLayoutNavigation } from "../../hooks/layout-navigation-hook";
import { useOktaAuth } from "@okta/okta-react";

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const {
    getFiltersData,
    filtersData,
    getStartOfMonth,
    getEndOfMonth,
    getUserRoles,
    userInitial,
    getGSPPFiltersData,
    gsppFiltersData
  } = useLayoutNavigation();
  const { authState } = useOktaAuth();

  const [selectedFilters, setSelectedFilters] = useState({
    selectedProduct: null,
    selectedModel: null,
    selectedSector: null,
    selectedMarket: null,
    selectedPlant: null
  });
  const [transformedData, setTransformedData] = useState({});
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedRetailer, setSelectedRetailer] = useState({});
  const [selectedPPRegion, setSelectedPPRegion] = useState({});
  const [selectedZone, setSelectedZone] = useState({});
  const [selectedDistrict, setSelectedDistrict] = useState({});
  const [categories, setSelectedCategory] = useState(null);
  const [selectedApplicationNames, setSelectedApplicaitonNames] =
    useState(null);
  const [selectedPlant, setSelectedPlant] = useState(null);

  const basicOption = { value: "All", label: "All" };
  // These states include current selected options for the tools and projects filter.
  const [selectedTool, setSelectedTool] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  // These states include all possible options for the tools and projects which used in clearAll function.
  const [toolAllOptions, setToolAllOptions] = useState([]);
  const [projectAllOptions, setProjectAllOptions] = useState([]);

  // These states include all possible options for stores in shipment visibility and selected store.
  const [selectedStore, setSelectedStore] = useState(basicOption);
  const [storeOptions, setStoreOptions] = useState([basicOption]);

  const [selectedStartDate, setSelectedStartDate] = useState(
    getStartOfMonth(new Date(), true)
    // new Date(Date.now() - 30 * 24 * 60 * 60 * 1000) // 30 days back
  );
  const [startUTCDate, setStartUTCDate] = useState(
    getStartOfMonth(new Date())?.toISOString()
    // new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString()
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    getEndOfMonth(new Date())
  );
  const [endUTCDate, setEndUTCDate] = useState(
    getEndOfMonth(new Date()).toISOString()
  );
  const [ytdCheckbox, setYTDCheckbox] = useState(true);
  const [betaFeatureToggle, setBetaFeatureToggle] = useState(false);

  const [transformedPPData, setTransformedPPData] = useState({});

  useEffect(() => {
    getUserRoles();
  }, []);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      getFiltersData({
        variables: {}
      });
      getGSPPFiltersData({
        variables: {}
      });
    }
  }, [getFiltersData, authState, getGSPPFiltersData]);

  const getAllProducts = () => {
    const allProducts = filtersData?.programs?.reduce((acc, program) => {
      return acc.concat(
        program.products.map((product) => ({
          ...product,
          programId: program.programId,
          programName: program.programName,
          programFullName: program?.programFullName,
          id: product.productId,
          label: product.productName,
          value: product.productName
        }))
      );
    }, []);
    return allProducts;
  };

  const getAllSectors = () => {
    const allSectors = filtersData?.programs?.reduce((acc, program) => {
      return acc.concat(
        program.products.reduce((prodAcc, product) => {
          return prodAcc.concat(
            product.models.reduce((modelAcc, model) => {
              return modelAcc.concat(
                model.sectors.map((sector) => ({
                  ...sector,
                  programId: program.programId,
                  productId: product.productId,
                  productName: product.productName,
                  modelId: model.modelId,
                  modelName: model.modelName,
                  id: sector.sectorId,
                  label: sector.sectorName,
                  value: sector.sectorName
                }))
              );
            }, [])
          );
        }, [])
      );
    }, []);
    return allSectors;
  };

  const getAllMarkets = () => {
    const allMarkets = filtersData?.programs?.reduce((acc, program) => {
      return acc.concat(
        program.products.reduce((prodAcc, product) => {
          return prodAcc.concat(
            product.models.reduce((modelAcc, model) => {
              return modelAcc.concat(
                model.sectors.reduce((sectorAcc, sector) => {
                  return sectorAcc.concat(
                    sector.markets.map((market) => ({
                      ...market,
                      programId: program.programId,
                      productId: product.productId,
                      sectorId: sector.sectorId,
                      modelId: model.modelId,
                      id: market.marketId,
                      label: market.marketName,
                      value: market.marketName
                    }))
                  );
                }, [])
              );
            }, [])
          );
        }, [])
      );
    }, []);
    return allMarkets;
  };

  const getAllModels = () => {
    let allModels = [];
    filtersData?.programs.forEach((program) => {
      // Iterate over products in each program
      program?.products.forEach((product) => {
        const models = product?.models.filter(
          (model) => model.modelId !== null
        );
        allModels = allModels.concat(
          models.map((model) => ({
            label: model?.modelName,
            value: model?.modelName,
            id: model?.modelId,
            modelName: model?.modelName,
            productId: product?.productId,
            programId: program?.programId
          }))
        );
      });
    });

    return allModels;
  };

  const getAllApplications = () => {
    const allApplicationNames = filtersData?.programs?.reduce(
      (acc, program) => {
        return acc.concat(
          program.products.reduce((prodAcc, product) => {
            return prodAcc.concat(
              product.models.reduce((modelAcc, model) => {
                return modelAcc.concat(
                  model.sectors.reduce((sectorAcc, sector) => {
                    return sectorAcc.concat(
                      sector.markets.reduce((marketAcc, market) => {
                        return marketAcc.concat(
                          market.applicationNames.map((appName) => ({
                            platform: appName,
                            programId: program.programId,
                            productId: product.productId,
                            sectorId: sector.sectorId,
                            modelId: model.modelId,
                            marketId: market.marketId
                          }))
                        );
                      }, [])
                    );
                  }, [])
                );
              }, [])
            );
          }, [])
        );
      },
      []
    );
    return allApplicationNames;
  };

  const getAllPlants = () => {
    const allSectors = filtersData?.programs?.reduce((acc, program) => {
      return acc.concat(
        program.products.reduce((prodAcc, product) => {
          return prodAcc.concat(
            product.models.reduce((modelAcc, model) => {
              return modelAcc.concat(
                model.sectors.flatMap((sector) => {
                  return sector?.plants?.map((plant) => {
                    return {
                      id: plant.plantId,
                      label: plant.plantName,
                      value: plant.plantName,
                      sectorId: sector.sectorId,
                      sectorName: sector.sectorName,
                    };
                  });
                })
              );
            }, [])
          );
        }, [])
      );
    }, []);
    return allSectors;
  };

  useEffect(() => {
    if (Object.keys(filtersData).length > 0) {
      const updatedData = {
        programs: filtersData?.programs?.map(
          ({ programId, programName, programFullName, products }) => ({
            programId,
            programName,
            programFullName,
            id: programId,
            label: programName,
            value: programName,
            products
          })
        ),
        products: getAllProducts(),
        models: getAllModels(),
        sectors: getAllSectors(),
        markets: getAllMarkets(),
        plants: getAllPlants(),
        applicationNames: getAllApplications(),
        categories: filtersData?.categories
      };
      setSelectedCategory(filtersData?.categories);
      setTransformedData(updatedData);
    }
  }, [filtersData]);

  useEffect(() => {
    if (Object.keys(gsppFiltersData).length > 0) {
      const updatedData = {
        regions: gsppFiltersData[0]?.retailers[0].regions.map(
          (r) => r.regionName
        ),
        zones: gsppFiltersData[0]?.retailers[0].regions
          .map((r) => r.zones)[0]
          .map((z) => z.zoneName),
        districts: gsppFiltersData[0]?.retailers[0].regions.map(
          (r) => r.zones[0]
        )[0].districts
      };
      setTransformedPPData(updatedData);
    }
  }, [gsppFiltersData]);

  const values = {
    regionValues: transformedData?.programs ?? [],
    selectedProgram,
    setSelectedProgram,
    productValues: transformedData?.products ?? [],
    selectedProduct,
    setSelectedProduct,
    modelValues: transformedData?.models ?? [],
    selectedModel,
    setSelectedModel,
    sectorValues: transformedData?.sectors ?? [],
    selectedSector,
    setSelectedSector,
    marketValues: transformedData?.markets ?? [],
    selectedMarket,
    setSelectedMarket,
    selectedCustomer,
    setSelectedCustomer,
    selectedRetailer,
    setSelectedRetailer,
    selectedPPRegion,
    setSelectedPPRegion,
    selectedZone,
    setSelectedZone,
    selectedDistrict,
    setSelectedDistrict,
    selectedStartDate,
    setSelectedStartDate,
    startUTCDate,
    setStartUTCDate,
    selectedEndDate,
    setSelectedEndDate,
    endUTCDate,
    setEndUTCDate,
    categories,
    setSelectedCategory,
    selectedApplicationNames,
    setSelectedApplicaitonNames,
    selectedTool,
    setSelectedTool,
    selectedProject,
    setSelectedProject,
    toolAllOptions,
    setToolAllOptions,
    projectAllOptions,
    setProjectAllOptions,
    selectedStore,
    setSelectedStore,
    storeOptions,
    setStoreOptions,
    allApplicationNames: transformedData?.applicationNames ?? [],
    userInitial,
    selectedFilters,
    setSelectedFilters,
    ytdCheckbox,
    setYTDCheckbox,
    betaFeatureToggle,
    setBetaFeatureToggle,
    allRegions: transformedPPData?.regions ?? [],
    allZones: transformedPPData?.zones ?? [],
    allDistricts: transformedPPData.districts ?? [],
    plantValues: transformedData?.plants ?? [],
    setSelectedPlant,
    selectedPlant
  };

  return (
    <FilterContext.Provider value={values}>{children}</FilterContext.Provider>
  );
};
